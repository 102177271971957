import classNames from "classnames";
import { differenceInCalendarDays } from "date-fns";
import moment from "moment";
import { useState } from "react";
import {
  ActiveModifiers,
  ClassNames as PickerClassNames,
  DateRange,
  DayModifiers,
  DayPicker,
  DayPickerProps,
  Row,
  RowProps,
} from "react-day-picker";
import styles from "react-day-picker/dist/style.module.css";
import { useIntl } from "react-intl";

import { CalendarIcon } from "../icons";
import { Input, InputProps } from "../input";
import { KEYS, useOnKeyPress } from "../utils";
import overrideStyles from "./DatePicker.module.scss";

type OptionalInputProps =
  | { input?: false; inputProps?: never }
  | {
      input: true;
      inputProps?: Omit<InputProps, "value" | "placeholder"> & { placeholder?: string };
    };

type OnDayClickProps = {
  day: Date;
  activeModifiers: ActiveModifiers;
  event: React.MouseEvent;
  range?: DateRange;
};

interface Props extends Pick<DayPickerProps, "numberOfMonths" | "modifiers"> {
  mode?: "single" | "range";
  onDayClick: (props: OnDayClickProps) => void;
  preselectedDay?: Date | string;
  resetSelection?: boolean;
  initialRange?: DateRange;
  initialMonth?: DayPickerProps["month"];
  onlyFuture?: boolean;
  withMonthYearDropdown?: boolean;
  yearDropdownRange?: [number, number];
  errorMessage?: string;
}

export type DatePickerProps = Props & OptionalInputProps;

const dateFormat = "DD/MM/YYYY";

function isPastDate(date: Date) {
  return differenceInCalendarDays(date, new Date()) < 0;
}

function OnlyFutureRow(props: RowProps) {
  const isPastRow = props.dates.every(isPastDate);
  if (isPastRow) return null;
  return <Row {...props} />;
}

const datePickerClassNames: PickerClassNames = {
  ...styles,
  ...overrideStyles,
};

function getFutureDatesProps(onlyFuture?: boolean) {
  if (!onlyFuture) return {};

  return {
    fromDate: new Date(),
    components: { Row: OnlyFutureRow },
    hidden: isPastDate,
  };
}

const WEEKDAYS_SHORT = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
export default function DatePicker({
  input = false,
  mode = "single",
  onDayClick,
  numberOfMonths = 1,
  preselectedDay = "",
  initialRange,
  initialMonth,
  onlyFuture,
  withMonthYearDropdown = false,
  yearDropdownRange = [new Date().getFullYear() - 10, new Date().getFullYear() + 2],
  inputProps = {},
  errorMessage,
}: DatePickerProps) {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedDay, setSelectedDay] = useState(preselectedDay);
  const [range, setRange] = useState(initialRange);
  const intl = useIntl();

  const modifiers = { start: range?.from, end: range?.to };

  useOnKeyPress(KEYS.ESCAPE, () => setShowPicker(false), showPicker);

  const handleDayClick = (day: Date, activeModifiers: ActiveModifiers, event: React.MouseEvent) => {
    let activeRange = {
      from: range?.from || day,
      to: range?.to || day,
    };

    const rangeInOrder = activeRange.from.getTime() < activeRange.to.getTime();

    // Make sure range is always in order (from < to)
    if (!rangeInOrder) {
      activeRange = {
        from: activeRange.to,
        to: activeRange.from,
      };
    }

    setShowPicker(!showPicker);
    setSelectedDay(moment(day).format(dateFormat));
    onDayClick?.({ day, activeModifiers, event, range: activeRange });
  };

  function getSelectedDay() {
    if (range) {
      return `${moment(range?.from).format(dateFormat)} - ${moment(range?.to).format(dateFormat)}`;
    }

    if (!selectedDay) {
      return "";
    }

    return selectedDay.toString();
  }

  const handleSelect = (nextRange: DateRange | undefined, selectedDay: Date) => {
    // whenever a new date is selecte the range is reset
    setRange((range) => {
      if (range?.from && range?.to) return { from: selectedDay };
      return nextRange as DateRange;
    });
  };

  function getModeAndRangeProps(mode: "single" | "range") {
    if (mode === "single") {
      return { mode };
    }

    return {
      selected: range,
      onSelect: handleSelect,
      mode: mode,
    };
  }

  return input ? (
    <>
      <Input
        value={getSelectedDay()}
        leftIcon={CalendarIcon}
        onClick={() => setShowPicker(!showPicker)}
        onChange={() => undefined}
        {...inputProps}
        placeholder={
          inputProps.placeholder ||
          intl.formatMessage({
            defaultMessage: "Select a date",
            id: "7qOQpv",
          })
        }
        state={errorMessage ? "ERROR" : "DEFAULT"}
      />
      {showPicker && (
        <>
          <div
            className={classNames({
              "grid absolute z-10 mt-2 h-min place-content-center rounded bg-white shadow-lg":
                showPicker,
            })}
          >
            <DayPicker
              mode={mode}
              numberOfMonths={numberOfMonths}
              defaultMonth={range?.from || initialMonth || undefined}
              onDayClick={handleDayClick}
              classNames={datePickerClassNames}
              modifiers={modifiers.start && modifiers.end ? (modifiers as DayModifiers) : undefined}
              showOutsideDays={false} // Hide outside days as disyplaing them in light grey breaks AA color contrast rules
              formatters={{
                formatWeekdayName: (date) => WEEKDAYS_SHORT[date.getDay()],
              }}
              {...getFutureDatesProps(onlyFuture)}
              {...(withMonthYearDropdown && {
                fromYear: yearDropdownRange[0],
                toYear: yearDropdownRange[1],
                captionLayout: "dropdown-buttons",
              })}
            />
          </div>
          <div
            className="fixed top-0 left-0 z-0 h-screen w-screen"
            onClick={() => setShowPicker(false)}
          />
        </>
      )}
    </>
  ) : (
    <DayPicker
      {...getModeAndRangeProps(mode)}
      numberOfMonths={numberOfMonths}
      defaultMonth={range?.from || initialMonth || undefined}
      onDayClick={handleDayClick}
      modifiers={modifiers as DayModifiers}
      classNames={datePickerClassNames}
      showOutsideDays={false} // Hide outside days as disyplaing them in light grey breaks AA color contrast rules
      formatters={{
        formatWeekdayName: (date) => WEEKDAYS_SHORT[date.getDay()],
      }}
      {...getFutureDatesProps(onlyFuture)}
      {...(withMonthYearDropdown && {
        fromYear: yearDropdownRange[0],
        toYear: yearDropdownRange[1],
        captionLayout: "dropdown-buttons",
      })}
    />
  );
}
