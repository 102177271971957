import classNames from "classnames";
import { ChangeEventHandler, FocusEventHandler, ReactNode } from "react";

import { Input, InputProps, SizeVariant } from "../input";
import { ThemeColor } from "../types";
import { FormElement, FormElementProps } from "./FormElement";
import { FormLabel } from "./FormLabel";

interface Props {
  title: ReactNode;
  required?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  value: string;
  placeholder: string;
  disabled?: boolean;
  inputSize?: SizeVariant;
  layout?: "VERTICAL" | "HORIZONTAL";
  spanContent?: string;
  labelColor?: ThemeColor;
  errorMessage?: string;
  type?: InputProps["type"];
  elementSize?: FormElementProps["size"];
}

export const FormInput = ({
  title,
  required = true,
  onChange,
  onBlur,
  value,
  placeholder,
  disabled = false,
  inputSize = "REGULAR",
  layout = "VERTICAL",
  spanContent = "*",
  labelColor = "blueGray600",
  elementSize = "grid",
  errorMessage,
  type,
}: Props) => (
  <FormElement size={elementSize}>
    <div
      className={classNames("flex", {
        "flex-col": layout === "VERTICAL",
        "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
      })}
    >
      <FormLabel size="xs" spacing={{ mb: 1 }} color={labelColor}>
        {title}
        {required && <span className="text-red700 ml-1">{spanContent}</span>}
      </FormLabel>
      <div
        className={classNames("inline-block", {
          "w-1/2": layout !== "VERTICAL",
        })}
      >
        <Input
          size={inputSize}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          value={value}
          fluid
          disabled={disabled}
          placeholder={placeholder}
          state={errorMessage ? "ERROR" : "DEFAULT"}
          type={type}
        />
      </div>
    </div>
    {errorMessage && (
      <div
        className={classNames("flex -mb-4", {
          "flex-col": layout === "VERTICAL",
          "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
        })}
      >
        <div />
        <span className="text-red700 text-xs">{errorMessage}</span>
      </div>
    )}
  </FormElement>
);
